<template>
  <base-section
    id="features"
    class="grey darken-4"
  >
    <!-- <base-section-heading
      icon='mdi-auto-fix'
      title='Features'
      class='white--text'
    /> -->
    <base-section-heading
      title="Features"
      class="white--text"
    />
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="6"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
import { mdiClockFast, mdiBomb, mdiAccountMultiplePlus, mdiIncognito, mdiNewBox  } from '@mdi/js';

  export default {
    name: 'SectionFeatures',
    data: () => ({
      cards: [
        {
          icon: mdiClockFast,
          title: 'Instant Voice + Text',
          text: 'Hop into a <strong>personal voice chat in seconds</strong> with our <em>ez4ppl2type™</em> URLs.<br/><sub>Standard Discord URL also provided.</sub>',
        },
        {
          icon: mdiBomb,
          title: 'Disposable',
          text: 'Your server will be <strong><em>destroyed</em></strong> once your party disconnects.<br/><sub>Clear the clutter of random servers...</sub>',
        },
        // {
        //   icon: mdiAccountMultiplePlus,
        //   title: 'Add New Friends',
        //   text: 'Invite people to your private party.<br/><sup>Decide whether they\'re worthy...</sup><br/><strong>Click</strong> <em>\'Add Friend\'</em> or <em>\'Invite to Server\'</em>.',
        // },
        {
          icon: mdiIncognito,
          title: 'No Extra Logins',
          text: 'Use your existing Discord account.<br/>OAuth <strong>NOT</strong> required.<br/><sub>If you\'re not logged in, Discord will prompt you.</sub>',
        },
        {
          icon: mdiNewBox,
          title: 'Activites BETA',
          text: 'Access Discord\'s <strong>exclusive</strong> <em>Activites Beta</em>.<br/><sub>Play Games or Watch YouTube Together within Discord.</sub>',
        },
      ],
    }),
  }
</script>

<style>
svg{
  width: 25px!important;
}
</style>